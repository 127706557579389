import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a2345d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loading-panel" }
const _hoisted_2 = {
  class: "progress-thumb",
  ref: "thumbRef"
}
const _hoisted_3 = { class: "loading-tip" }
const _hoisted_4 = {
  key: 0,
  class: "transition-wrapper"
}
const _hoisted_5 = { class: "tip-content" }
const _hoisted_6 = { class: "tc-prompt" }
const _hoisted_7 = {
  key: 1,
  class: "transition-wrapper",
  loading: "",
  style: {"justify-content":"center","align-items":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MetaImage = _resolveComponent("MetaImage")!
  const _component_WidthRelatedBox = _resolveComponent("WidthRelatedBox")!
  const _component_PromptTagGroup = _resolveComponent("PromptTagGroup")!
  const _component_RotateRing = _resolveComponent("RotateRing")!
  const _component_FadeTransition = _resolveComponent("FadeTransition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["progress-bar", {hidden:!_ctx.progressBarVisible}])
    }, [
      _createElementVNode("div", _hoisted_2, null, 512)
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_FadeTransition, null, {
        default: _withCtx(() => [
          (_ctx.tempTip)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_WidthRelatedBox, {
                  class: "tip-img",
                  height: "30%"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MetaImage, {
                      class: "tip-img-entity",
                      src: _ctx.tempTip.urlSmall
                    }, null, 8, ["src"])
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_5, [
                  (_ctx.wait)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.TipRenderTemplates.RELAX_MODE), {
                        key: 0,
                        enWaitingTip: _ctx.enWaitingTip,
                        cnWaitingTip: _ctx.cnWaitingTip
                      }, null, 8, ["enWaitingTip", "cnWaitingTip"]))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.tempTip.prompt), 1),
                        _createVNode(_component_PromptTagGroup, {
                          class: "tc-prompt-tags",
                          texts: _ctx.tempTip.properties
                        }, null, 8, ["texts"])
                      ], 64))
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_RotateRing)
              ]))
        ]),
        _: 1
      })
    ])
  ]))
}