
import {Options, Vue} from 'vue-class-component';
import SlideI18nText from "@/components/SlideI18nText.vue";
import WidthRelatedBox from "@/components/WidthRelatedBox.vue";
import anime, {AnimeInstance} from "animejs";
import {MetaLocales} from "@/types/Lang";
import {h} from "vue";
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import {colorLog} from "@/utils";
import SvgIcon from "@/components/SvgIcon.vue";
import {CommunityCreationData} from "@/types/CommunityCreationData";
import PromptTag from "@/components/PromptTag.vue";
import {getCommunityCreations, getLoadingDisplayingImages} from "@/api";
import RotateRing from "@/components/RotateRing.vue";
import PromptTagGroup from "@/components/PromptTagGroup.vue";

enum TipRenderTemplates {
  RELAX_MODE = 'relax-mode'
}

type ExampleTip = {
  img:string;
  mode:'example';
  template:Record<MetaLocales,{detail:string,tutorial:string}>;
}

type InstructionTip = {
  img:string;
  mode:'instruction';
  template:TipRenderTemplates;
}

@Options<LoadingPanel>({
  components: {
    PromptTag,
    RotateRing,
    PromptTagGroup,
    SlideI18nText,
    WidthRelatedBox,
    FadeTransition,
    [TipRenderTemplates.RELAX_MODE]: {
      name: TipRenderTemplates.RELAX_MODE,
      props:['enWaitingTip','cnWaitingTip'],
      render: (ctx: LoadingPanel) => {
        return h('ul', {class: 'instruction-wrapper'}, [
          h('li', {class: 'instruction-li'},
              [
                h('em', {class: 'il-title'}, [h(SlideI18nText, {path: 'relaxMode'}), ':']),
                ' ',
                ctx.$t('relaxModeTip_1'),
                h('em', {
                  style: "text-decoration:underline",
                  class: 'il-time'
                }, ctx.$t('relaxModeTip_2', {time: ctx.$i18n.locale === 'en' ? ctx.enWaitingTip : ctx.cnWaitingTip})),
                '.'
              ]
          ),
          h('li',{class: 'instruction-li'},[
            ctx.$i18n.locale === MetaLocales['ZH-CN'] ? h('span','当图片生成后，您可以在右上角的' ) : h('span','Once the image is generated, it can be viewed within' ),
            h(SvgIcon,{style:'width:17px;height:17px;vertical-align:middle;',name:'ring'}),
            ctx.$i18n.locale === MetaLocales['ZH-CN'] ? h('span','里看到。' ) : h('span','at the top right corner.')
          ])

        ])
      }
    }
  },
  props: {
    progress:{
      required:false,
      default:0
    },
    progressBarVisible:{
      required:false,
      type:Boolean,
      default:true
    },
    wait:{
      required:false,
      type:Number,//单位:秒
      default:0
    }
  },
  watch:{
    progress:{
      handler(newVal:number){
        this.interProgress = newVal;
      },
      immediate:true
    },
    interProgress:{
      handler(newVal:number,oldVal:number){
        this.updateProgress();
        this.$emit('update:progress',newVal);
      }
    },
  },
  computed:{
    enWaitingTip(){
      const texts:string[] = [];
      const hours = Math.floor(this.wait / (60 * 60));
      if(hours){
        texts.push(`${hours} hour${multi(hours)}`);
      }
      let remain = this.wait % (60 * 60);
      const minutes = Math.floor(remain / 60);
      if(minutes){
        texts.push(`${minutes} minute${multi(minutes)}`);
      }
      remain = remain % 60;
      texts.push(`${remain} second${multi(remain)}`);

      function multi(n:number){
        return n > 1 ? 's' : '';
      }


      return texts.join(' and ');
    },
    cnWaitingTip(){
      const texts:string[] = [];
      const hours = Math.floor(this.wait / (60 * 60));
      if(hours){
        texts.push(`${hours} 小时`);
      }
      let remain = this.wait % (60 * 60);
      const minutes = Math.floor(remain / 60);
      if(minutes){
        texts.push(`${minutes} 分钟`);
      }
      remain = remain % 60;
      texts.push(`${remain} 秒`);

      function multi(n:number){
        return n > 1 ? 's' : '';
      }

      return texts.join(' ');
    }
  }
})
export default class LoadingPanel extends Vue {
  progress?:number;
  progressTimeline?:AnimeInstance;
  progressBarVisible!:boolean;
  wait!:number;
  enWaitingTip!:string;
  cnWaitingTip!:string;
  interProgress = 0;
  currentTipIndex = 0;
  TipRenderTemplates = TipRenderTemplates;
  tempTip:CommunityCreationData | null = null;
  tips:( ExampleTip | InstructionTip)[] = [
    {
      img:require('../assets/images/example2.png'),
      mode:'example',
      template:{
        "en": {
          "detail": "Add \"digital art\" for striking and high-quality images.",
          "tutorial": "A fortune-telling shiba inu reading your fate in a giant hamburger,digital art."
        },
        "zh-cn": {
          "detail": "添加“数字艺术”以获得引人注目的高质量图像。",
          "tutorial": "算命的柴犬在一个巨大的汉堡包中解读你的命运，数字艺术。"
        },
        // "zh-cht": {
        //   "detail": "添加「數字藝術」以獲得引人註目的高質量圖像。",
        //   "tutorial": "算命的柴犬在一個巨大的漢堡包中解讀你的命運，數字藝術。"
        // }
      }
    },
    {
      img:require('../assets/images/example5.png'),
      mode:'example',
      template:{
        "en": {
          "detail": "Use Illustration styles —— “Ballpoint pen art” ",
          "tutorial": "“Cat wearing sunglasses sitting in a chair, ball-point pen art”"
        },
        "zh-cn": {
          "detail": "插图风格的运用——圆珠笔艺术",
          "tutorial": "猫戴墨镜坐在椅子上，圆珠笔艺术"
        },
      }
    },
    {
      img:require('../assets/images/example5.png'),
      mode:'instruction',
      template:TipRenderTemplates.RELAX_MODE
    }
  ];

  intervalId?:any;
  async mounted(){
    this.updateProgress();
    // this.intervalId = setInterval(() => {
    //   this.currentTipIndex = (this.currentTipIndex + 1) % this.tips.length;
    // },10000);

    const creations = await getLoadingDisplayingImages();
    this.tempTip = creations[0];
    let i = 0;
    this.intervalId = setInterval(() => {
      this.tempTip = creations[++i % creations.length];
    },10000);
  }

  unmounted(){
    clearInterval(this.intervalId);
  }

  activated(){
  }

  updateProgress(){
    const thumb = this.$refs.thumbRef as HTMLElement;
    this.progressTimeline?.pause();
    if(this.interProgress === 0){
      anime.set(thumb,{
        right:'100%'
      });
    }else{
      this.progressTimeline = anime({
        targets:thumb,
        right: 100 - this.interProgress + '%',
        easing:'spring(1, 80, 13, 0)',
      });
    }

  }
}
